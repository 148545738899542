<template>

<app-screen :nopadding="current === 1" :title="(current !== 1) ? 'Welcome' : false" :centralised="current === 3" :pipTotal="(current === 1) ? 0 : 3" :pipCurrent="current" class="screen-welcome">

	<com-intro v-if="current === 1" v-on:proceed="onProceedClick" />
	<com-message v-if="current === 2" v-on:ended="is.played = true" />
	<com-rules v-if="current === 3" />

	<template v-slot:buttons>

		<app-button :text="texts.ob.message.button" v-if="current === 2" v-on:click="onProceedClick" />
		<app-button :text="texts.ob.rules.button" v-if="current === 3" v-on:click="onProceedClick" />

	</template>
	
</app-screen>

</template>

<script>

import comIntro from './welcome/Intro'
import comMessage from './welcome/Message'
import comRules from './welcome/Rules'

export default {

	components: {
		'com-intro': comIntro,
		'com-message': comMessage,
		'com-rules': comRules
	},

	data: function() {

		return {
			current: 1,
			is: {
				played: false
			}
		}

	},

	methods: {

		onProceedClick: function() {

			if (this.current < 3) {

				this.current++

			} else {

				this.$api.request(this.$constants.api.tasks.start).then(function(response) {

					this.$store.commit('session', response.session)

				}.bind(this))

				this.$router.push({
					name: 'Categories'
				})

			}

		}

	}

}

</script>

<style scoped>

.screen-welcome {
	background-color: #fff!important;
}

</style>

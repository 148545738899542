<template>

<div class="intro">

	<div class="intro-logo"><img :src="texts.ob.intro.client" /></div>

	<div class="intro-content">

		<div class="intro-text" v-html="text"></div>

		<app-button :text="texts.ob.intro.button" v-on:click="$emit('proceed')" class="intro-button" />
	
	</div>

</div>

</template>

<script>

export default {

	computed: {

		text: function() {

			return this.texts.ob.intro.text.replace('{{firstname}}', this.$store.getters['session'].name.split(' ')[0])

		}

	}

}

</script>

<style scoped>

.intro {
	width: 100%;
	flex-direction: column;
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.intro-text {
	color: #fff;
    font-size: 32px;
    font-weight: 400;
	line-height: 40px;
	padding: 32px 0px;
	flex-grow: 1;
	text-align: center;
}

.intro-location {
	color: #fff;
    font-size: 40px;
    font-weight: 400;
	margin-bottom: 32px;
	margin-top: -50px;
}

.intro-logo {
	width: 100%;
	padding: 30px;
	text-align: center;
	flex-shrink: 0;
}

.intro-logo img {
	width: auto;
	max-width: calc(100% - 60px);
	height: auto;
	max-height: 180px;
}

.intro-button {
	width: 100%;
	flex-shrink: 0;
}

.intro-content {
	flex-grow: 1;
	background-position: 100% 0%;
	background-size: cover;
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background-image: url(~@/assets/background-green.jpg);
}

</style>

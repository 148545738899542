<template>

<app-screen :menu="true" :loading="is.loading" title="Leaderboard" toolbar="leaderboard" :tabCurrent="tab" :tabs="tabs" v-on:tab="onTabChange">

	<template v-if="tab === 'teams'">

		<div v-for="(team, index) in teams" :key="team.id" class="leaderboard-item" :class="{'is-own': team.id === ownTeam}">

			<div class="leaderboard-item-name">{{ team.name }}</div>
			<div class="leaderboard-item-score">Avg. score: {{ team.average | round }}</div>
			<div class="leaderboard-item-participation">Greenstars: {{ team.greenstars }}</div>
			<div class="leaderboard-item-position">{{ index + 1 | nth }}</div>

		</div>

	</template>

	<template v-if="tab === 'users'">

		<div v-for="(user, index) in users" :key="user.id" class="leaderboard-item" :class="{'is-own': user.id === $store.getters['session'].key}">

			<div class="leaderboard-item-name">{{ user.name }}</div>
			<div class="leaderboard-item-score">Score: {{ user.score }}</div>
			<div class="leaderboard-item-position">{{ index + 1 | nth }}</div>

		</div>

	</template>
	
</app-screen>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true
			},
			tab: 'teams',
			tabs: {
				teams: 'Neighborhood',
				users: 'Individual'
			},
			users: [],
			teams: []
		}

	},

	created: function() {

		this.refresh()

	},

	computed: {

		ownTeam: function() {

			return this.$store.getters['session'].team

		}

	},

	methods: {

		onTabChange: function(value) {

			this.tab = value

			this.refresh()

		},

		refresh: function() {

			this.is.loading = true

			this.$api.request(this.$constants.api.leaderboard).then(function(response) {

				this.teams = response.teams
				this.users = response.users

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.leaderboard-text {
	color: #428103;
	font-size: 20px;
	line-height: 24px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1;
	margin-bottom: 32px;
}

.leaderboard-item {
	padding: 20px 64px 20px 20px;
	color: #428103;
	border-radius: 20px;
	background-color: #fff;
	margin-bottom:10px;
}

.leaderboard-item.is-own {
	background-color: #428103;
	color: #fff;
}

.leaderboard-item-name {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.leaderboard-item-score {
	text-transform: uppercase;
	font-size: 16px;
	margin-bottom: 4px;
}

.leaderboard-item-participation {
	text-transform: uppercase;
	font-size: 16px;
}

.leaderboard-item-position {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -20px;
	border-radius: 20px;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	line-height: 40px;
	background-color: #428103;
	color: #fff;
}

.leaderboard-item.is-own .leaderboard-item-position {
	background-color: #fff;
	color: #428103;
}


</style>
